require('./bootstrap');

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { Inertia } from '@inertiajs/inertia';
import { i18nVue } from 'laravel-vue-i18n'
	
// import algoliasearch from 'algoliasearch/lite';
// import InstantSearch from 'vue-instantsearch/vue3/es';
// import { AisInstantSearch, AisAutocomplete } from 'vue-instantsearch/vue3/es';
import PublicLayout from "@/Layouts/Guest.vue";

createInertiaApp({
    title: (title) => `${title}`,
    resolve: async (name) => {
        let page = (await import(`./Pages/${name}.vue`)).default;
        page.layout ??= PublicLayout;
        return page;
    },
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(i18nVue, { 
                resolve: (lang) => import(`../../lang/${lang}.json`) 
            })
            // .use(InstantSearch)
            // .use(AisAutocomplete)            
            .mixin({ methods: { route } })
            .mount(el);
    },
});

InertiaProgress.init({ color: '#18bc9c' });

Inertia.on('navigate', (event) => {
    console.log('page_location', event.detail.page.url);
    // console.log('event', event.detail.page.component);
    /*
    gtag('config', 'G-SX60PK8T3G', {
        'page_location': event.detail.page.url
    });
    gtag('set', 'content_group', event.detail.page.component);
    (adsbygoogle = window.adsbygoogle || []).push({});
    */
})
